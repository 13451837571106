import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField,Button, FormControl} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';

import classNames from 'classnames';

import {styles} from '../layout/theme';
import Utils from '../api/api';

export class CategoryFAQList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false
    }
  }
  handleAdd(){
    this.context.router.history.push('/faqgroup/add');
  }
  handleSearch() {
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  render(){
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Tiêu đề',value:'nameVN'}, { title: 'Title', value: 'nameEN' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          nameVN: n.nameVN,
          nameEN: n.nameVN
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục nhóm FAQ</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormControl className={classes.col1}>
              <FormGroup className={classes.formRowInline}>
                <Button onClick={()=>{this.context.router.history.push('/faq')}} variant="contained" color="primary">FAQ</Button>
                <Button disabled={true}>FAQ Group</Button>
              </FormGroup>
            </FormControl>
          </Toolbar>
          <Toolbar className={classes.tableToolbar} style={{justifyContent:'flex-end'}}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
              margin="normal"/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiDragOrder={true} apiList='faqgroups' apiSingle='faqgroup' apiTable='faqgroup' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

CategoryFAQList.propTypes = {
  classes: PropTypes.object.isRequired,
};
CategoryFAQList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CategoryFAQList);
