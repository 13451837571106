import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CategoryFAQList from './category_faq_list';
import CategoryFAQDetail from './category_faq_detail';

class CategoryFAQ extends Component {
  componentDidMount(){
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined){
      view = <CategoryFAQList/>;
    }else{
      if(params.id===undefined){
        view = <CategoryFAQDetail/>;
      }else{
        view = <CategoryFAQDetail action={params.action}/>;
      }
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

CategoryFAQ.contextTypes = {
  router: PropTypes.object
};

export default CategoryFAQ;
